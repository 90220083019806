.lightHeader {
  background-color: rgba(255, 255, 255, 0.72) !important;
  box-shadow: inset 0px -1px 1px #e7ebf0 !important;
}

.darkHeader {
  background-color: rgba(0, 0, 0, 0.72) !important;
  box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.1) !important;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkContainer img {
  width: 38px;
  height: 38px;
  margin-right: 1em;
  vertical-align: middle !important;
}
